.navBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  height: 6rem;
  align-items: center;
  top: 0;
  background-color: var(--navy);
}

.navBar ul {
  display: flex;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: var(--white);
  width: 100%;
  justify-content: flex-end;
}

.navBar li {
  text-decoration: none;
  margin-right: 3%;
  font-size: 18px;
}

.logo {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: var(--white);
  font-size: 30px;
  margin-left: 3%;
}

.navBar li:hover {
  color: var(--yellow);
  cursor: pointer;
}

@media (max-width: 500px) {
  .navBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    height: 6rem;
    align-items: center;
    top: 0;
    background-color: var(--navy);
  }
  
  .navBar ul {
    display: flex;
    list-style: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: var(--white);
    width: 100%;
    justify-content: center;
  }
  
  .navBar li {
    text-decoration: none;
    margin-right: 3%;
    font-size: 16px;
  }
  
  .logo {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: var(--white);
    font-size: 30px;
    margin-left: 3%;
    display: none;
  }
  
  .navBar li:hover {
    color: var(--yellow);
    cursor: pointer;
  }
}