.landingContainer {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
}

.logoImg {
  width: 18%;
  margin-bottom: 30px;
}

.landingHeader {
  font-size: 60px;
  font-weight: 700;
  color: var(--navy);
  margin: 0;
}

@media (max-width: 500px) {
  .landingContainer {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
  }
  
  .logoImg {
    width: 18%;
    margin-bottom: 30px;
  }

  .landingHeader {
    font-size: 50px;
    font-weight: 700;
    color: var(--navy);
    margin: 0;
  }
}
